import { template as template_0b0d507500ed4f22a6bf1db8b2a0aa0c } from "@ember/template-compiler";
const FKControlMenuContainer = template_0b0d507500ed4f22a6bf1db8b2a0aa0c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
