import { template as template_063b2455dd8147f5a672ffdd4c67b4c6 } from "@ember/template-compiler";
const FKLabel = template_063b2455dd8147f5a672ffdd4c67b4c6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
