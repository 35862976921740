import { template as template_e9a7c56e21b748cc8d62c8810a08ed4d } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
const FKCol = template_e9a7c56e21b748cc8d62c8810a08ed4d(`
  <div class={{concatClass "form-kit__col" (if @size (concat "--col-" @size))}}>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKCol;
